.gray {
  color: #7B7B7B;
}
  
.black {
  color: #000000;
}
  
.error {
  color: #F44D00;
}

.yellow {
  color: #FFE9C5;
}

.dark-yellow {
  color: #DAAD50;
}

.white {
  color: #ffffff;
}

.creators-text {
  color: #ffa96f;
}
.circular {
  &.ex-r { background-color: #ff71a9; }
  &.r { background-color: #ff8975; }
  &.n { background-color: #fff368; }
  &.l { background-color: #86daff; }
  &.ex-l { background-color: #29a3ff; }
  &.not { background-color: #7B7B7B; }
  &.female { background-color: #ff705a; }
  &.male { background-color: #63bcff; }
  &.un { background-color: #fff25d; }
  &.g10 { background-color: #ffffff; }
  &.g20 { background-color: tomato; }
  &.g30 { background-color: orange; }
  &.g40 { background-color: dodgerblue; }
  &.g50 { background-color: mediumseagreen; }
  &.g60 { background-color: slateblue; }
  &.g70 { background-color: violet; }
  &.opaque {
    opacity: 0.55;
  }
}
.outerCircle {
  &.active {
    box-shadow: 0px 0px 3px 4px;
  }
}
.button {
  &.pressed {
    background-color: #DFDFDF;
    opacity: 0.5;
    color: rgba(0,0,250,0.7);
  }

  &.hover {
    background-color: #DFDFDF;
    opacity: 0.5; 
    .button-text {
      color: rgba(0,0,250,0.7);
    }
  }

  &.disabled { background-color: #DFDFDF; }

  &.hover.disabled {
    opacity: 1 !important;
  }

  &-gradient {
    background-image: linear-gradient(to right, #bcc1ff, #ffa96f);
    color: #000000 !important;
  }
  &-login {
    background-color: rgb(247, 227, 23);
    color: rgb(60, 30, 30) !important;

  }
  
  &-transparent { border: solid 1px #ffffff; }

  &-gray {
    opacity: 0.9;
    background-color: #17192a;
    color: #ffffff;
  }
  &-option { border: solid 1px #bbbbbb; }

  &.hot {
    border-color: #fff368;
  }
}

.header {
    background-color: #17192a;
    color: #ffffff;
  &-light {
    background-color: #f2f2f2;
  }
  .right {
    span {
      color: #fff368;
    }
  }
}

// Slider
.MuiSlider-markLabel {
  color: #f2f2f2 !important;
}

.MuiSlider-root {
  color: #f2f2f2 !important;
}

.MuiSlider-mark {
  background-color: #d8d8d8 !important;
}

.likertscale .MuiSlider-thumb {
  border: solid 2px #ffa96f !important;
  background-color: transparent !important;
}

.freeform-input {
    border: solid 3px gray;
    background-color: #fff !important;
    color: #000 !important;
    &::placeholder {
      color: rgba(0,0,0,0.4);
  }
}

.result-box {
  border: solid 1px #7b7b7b;
  background-color: #262b34;
  .opinion-box {
    .command-div {
      color: #fff368;
    }
    .demographic {
      font-size: 10px;
      line-height: 1;
    }
    .divider {
      opacity: 0.5;
      background-color: #d8d8d8;
    }
    .like .like-button {
      border: solid 1px #ffa96f;
    }
  }
}

.filter-column {
  .divider {
    opacity: 0.5;
    background-color: #d8d8d8;
  }
}

.MuiButtonBase-root {
  color: #ffffff !important;
}

.MuiSelect-select {
  color: #ffffff !important;
}

.MuiSelect-icon {
  color: #ffffff !important
}

.Mui-checked {
  color: #ffa96f !important;
}

.MuiMenu-paper {
  background-color: black !important;
}

.donut-segment {
  &:hover {
    opacity: 0.7;
  }
  &.active {
    opacity: 0.5;
  }
}

a {
  color: inherit;
}

.dropdown-button {
  color: rgba(255,255,255,0.7);
}