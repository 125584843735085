$width-boundary: 640px;
@mixin mobile {
    @media screen and (max-width: $width-boundary) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $width-boundary+1){
        @content;
    }
}

html, #root {
    height: 100%;
}
  
p, body {
    margin: 0;
    padding: 0;
}
  
body {
    @include desktop {
        background-image: url('../img/bg.jpg');
    }
    @include mobile {
        background-image: url('../img/bgmobile.jpg');
    }
    font-family: noto-sans-cjk-kr, sans-serif;
    color: white;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.welcome-box {
    @include desktop {
        margin-top: 94px !important;
        padding-bottom: 2em !important;
    }
    @include mobile {
        margin-top: 102px !important;
        padding-bottom: 4em !important;
    }
    width: 256px;
    height: 132px;
    margin: 67px auto;
}

.header {
    padding: 5px 0;
    width: 100%;
    height: 40px;
    opacity: 0.9;
    text-align: justify;
    // cursor: pointer;
    img {
        &.left {
            padding-left: 3%;
        }
        height: 40px;
    }
    .right {
        float: right;
        padding-right: 3%;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        span {
            padding: 2px 8px;
            border-radius: 16px;
            border: 1px solid #fff368;
        }
        .MuiSvgIcon-root {
            height: 100%;
        }
    }
}

.about-link {
    margin-top: 16px;
    margin-bottom: 16px;
}

.logos {
    // @include desktop {
    //     width: 120px !important;
    //     &.sm {
    //         width: 70px !important;
    //     }
    // }
    // @include mobile {
    //     width: 70px;
    // }
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    // text-align: justify;
    .col {
        width: 150px;

        &.first{
            text-align: right;
        }
        &.second{
            text-align: left;
        }
        .logo {
            height: 2.5em;
            max-width: 150px;
            // display: inline-block;
            margin: 8px 1em;
            &.sbs {
                height: 2em;
                padding-top: 0.25em;
                padding-bottom: 0.25em;
            }
        }
    }
}

.button {
    @include desktop {
        width: 620px !important;
        &.sm {
            width: 288px !important;
        }
    }
    @include mobile {
        width: 286px;
    }
    height: 44px;
    border-radius: 4px;
    margin: 16px auto;
    line-height: 44px;
    cursor: pointer;
    
    &.select {
        height: 40px !important;
        margin: 6px auto;
        text-overflow: ellipsis;
    }

    &.disabled { cursor: initial; }

    &-login {
        background-image: url("../img/new_login_btn.png");
        background-size: cover;
    }
    .button-text {
        z-index: 2;
    }
    // &.hot button-text{
    //     margin-right: 2em;
    // }
    &.hover.hot :after {
        content: "HOT";
        font-size: 1em;
        display: inline;
        vertical-align: top;
        margin-right: -10%;
        margin-left: 1em;
        color: rgba(0,0,250,0.7);
        border: 1px solid rgba(0,0,250,0.7);
        text-shadow: rgba(0,0,250,0.7) 1px 0 20px;
    }
    &.pressed.hot :after {
        content: "HOT";
        font-size: 1em;
        display: inline;
        vertical-align: top;
        margin-left: 1em;
        margin-right: -10%;
        color: rgba(0,0,250,0.7);
        border: 1px solid rgba(0,0,250,0.7);
        text-shadow: rgba(0,0,250,0.7) 1px 0 20px;
    }
    &.hot :after {
        content: "HOT";
        // width: 10%;
        font-size: 1em;
        display: inline;
        // height: 1em;
        // background-color: #fff368;
        vertical-align: top;
        margin-left: 1em;
        margin-right: -10%;
        // margin-left: 10%;
        color: #fff368;
        border: 1px solid #fff368;
        // padding: 0;
        text-shadow: #fff368 1px 0 20px;
    }

    &.done {
        border-color: #555555;
    }

    // &.done :before {
    //     content: "[DONE]";
    //     font-size: 1em;
    //     display: inline;
    //     // margin-left: -10%;
    //     // margin-right: 10%;
    // }

}

.bottom-text {
    text-align: center;
    @include desktop {
        width: 750px;
        margin: 0 auto;
    }
    @include mobile {
        width: 288px;
        margin: 0 auto;
    }
}

.issueBox {
    margin-bottom: 2em;
    .issue {
        &.hot:after {
        content: "HOT";
        // width: 10%;
        text-decoration: none !important;
        font-size: 1em;
        display: inline;
        // height: 1em;
        // background-color: #fff368;
        vertical-align: top;
        margin-left: 1em;
        margin-right: -10%;
        // margin-left: 10%;
        color: #fff368;
        border: 1px solid #fff368;
        // padding: 0;
        text-shadow: #fff368 1px 0 20px;
        }
    }
    .MuiSlider-root {
        touch-action: auto;
    }
}

.link {
    cursor: pointer;
}

.next {
    margin-bottom: 18px !important;
    bottom: 0px;
    left: 0px;
    width: 100%;
    justify-content: center;
    z-index: 1000;
}

.container {
    @include desktop {
        width: 750px;
        margin: auto;
    }
    @include mobile {
        width: 288px;
        margin: auto;
        overflow-y: scroll;
    }

    flex: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    &-outer {
        display: table;
        width: 100% !important;
    }

    &-inner {
        display: table-cell;
        vertical-align: middle;
        margin: auto;
    }
}

.relaxed-container {
    width: 95%;
    margin: 0 auto;
    flex: 1;
    @include mobile {
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
    }
}

.title {
    @include desktop {
        margin: 26px auto 53px auto;
    }
    @include mobile {
        margin: 24px auto 16px auto;
    }
    &.issue-title {
        @include desktop {
            margin-top: 40px;
            margin-bottom: 49px; 
        }
        @include mobile {
            margin-top: 24px;
            margin-bottom: 16px; 
        }
    }
    &.complete {
        top: 50%;
        position: relative;
    }

}

.result-action {
    margin-bottom: 8px;
}

.description {
    @include mobile {
        margin: 20px 0 26px 0;
    }
    height: 100%;
    overflow-y: scroll;
}

.MuiFab-root {
    // float: right;
    position: fixed !important;
    bottom: 1em;
    right: 1em;
}

.likertscale .MuiSlider-mark {
    height: 14px !important;
    width: 14px !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
    border-radius: 50% !important;
}

.customSlider .MuiSlider-mark {
    height: 14px !important;
    width: 2px !important;
    margin-top: -7px !important;
}

.MuiSlider-thumb {
    width: 24px !important;
    height: 24px !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
}

.MuiSlider-valueLabel {
    display: none !important;
}

.MuiSlider-markLabel {
    top: 44px !important;
}

.MuiSlider-track {
    width: 0% !important;
}

.MuiSlider-root {
    padding: 30px 0 !important;
}

.MuiGrid-container {
    height: 100% !important;
}

.freeform-input {
    border-radius: 4px;
    margin: auto;
    padding: 8px;

    @include desktop {
        width: 600px;
        height: 241px;
    }

    @include mobile {
        width: 272px;
        height: 272px;
    }
}

.result-box {
    border-radius: 4px;
    z-index: 100;
    @include desktop {
        width: 256px;
        height: 255px;
        margin: 12px;
        display: inline-flex;
        vertical-align: top;
    }

    @include mobile {
        // width: 288px;
        margin: 12px 16px auto;
    }

    &.edit {
        @include desktop {
            height: 305px !important;
        }
        .opinion-box {
            .opinion {
                @include desktop {
                    height: 182px !important;
                }
            }
        }
    }

    .opinion-box {
        margin: 16px;
        @include desktop {
            width: 100%;
        }
        .opinion-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            vertical-align: top;

            .command-div {
                border: 1px solid #fff368;
                border-radius: 16px;
                padding-left: 8px;
                padding-right: 8px;
                // padding-top: 2px;
                // padding-bottom: 2px;
                display: flex;
                align-content: center;
                .MuiSvgIcon-root { 
                    height: 20px;
                    width: 20px;
                }
                &.MuiSvgIcon-root { 
                    height: 20px;
                    width: 20px;
                }
            }
            .command-div {
                cursor: pointer;
            }
        }

        .command-edit {
            text-align: center;
            .freeform-input {
                width: 90%;
                font-size: 14px !important;
                height: 120%;
                margin-top: -16px;
            }
            .MuiSlider-root {
                width: 72% !important;
            }

        }
        
        .opinion {
            @include desktop {
                height: 132px;
            }
            overflow-y: scroll;
            right: -17px;
            box-sizing: content-box;
            word-break: break-all;
            word-wrap: normal;
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
            scrollbar-width: none;
        }
        .demographics {
            display: inline-flex;
            height: 28px;
            flex-flow: row;
            justify-content: space-between;
            align-content: flex-start;
            .demographic {
                display: inline-flex;
                margin-top: 16px;
                .circular {
                    margin-right: 4px;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                }
                .text {
                    margin-right: 8px;
                    line-height: 1.3;
                }
                &.age {
                    flex: 1 1 auto;
                }
                &.gender {
                    flex: 1 1 auto;
                }
                &.pol {
                    flex: 2 1 auto;
                }
                &.occ {
                    flex: 2 1 auto;
                }
            }
        }
        
        .divider {
            height: 1px;
            width: 100%;
            margin-top: 24px;
        }

        .like {
            margin-top: 12px;
            display: flex;
            
            .text {
                margin: 7px 0;
            }
            .like-button {
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                -ms-user-select: none;
                margin-left: auto;
                border-radius: 16px;
                width: 63px;
                height: 24px;
                top: -2px;
                display: flex;
                padding: 2px 2px;
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                }
                .heart {
                    width: 11px;
                    height: 12px;
                    padding: 2px;
                    margin: auto 6px;
                    &-selected {
                    background-image: url('../img/heart-select.png');
                    }
                    &-unselected {
                        background-image: url('../img/heart-unselect.png');
                    }
                }
                .text {
                    margin: 3px 0 !important;
                }
            }
        }
    }
}

.result-header {
    @include desktop {
        margin: 32px auto;
    }
    @include mobile {
        margin: 8px auto 0 auto;
    }
}

.labels {
    width: 100%;
    // height: 1em;
    .left-label {
        float: left;
    }
    .axis {
        float: none;
        background-color: #ffffff;
        height: 0.1em;
        display: inline-block;
        width: 100%;
    }
    .right-label {
        float: right;
    }
}

.issue-title {
    margin: 8px 0;
}

.filter-column {
    padding-left: 40px;
    .radio-filter {
        margin-top: 56px;
        .radio {
            margin-top: 8px;
        }
    }
    .dropdown-filter {
        margin-top: 32px;
        .filter {
            margin-top: 8px;
        }
    }
    .filter-item {
        display: flex;
        margin: 8px 0;
        .circular {
            margin: 2px 12px 2px 2px;
            border-radius: 50%;
            width: 8px;
            height: 8px;
        }
        cursor: pointer;
    }

    .divider {
        height: 1px;
        width: 80%;
        margin: 8px 0 16px 0;
    }
    .text {
        margin-top: 8px;
    }
}

svg.donut {
    margin: 16px 0 32px 0;
}

.donut-segment {
    cursor: pointer;
}

.position-absolute {
    position: absolute;
    &:hover {
        opacity: 0.5;
    }
}

.circular {
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

@keyframes glowing {
    0% { box-shadow: 0 0 0px 8px rgba(255,0,0,.8); }
    30% { box-shadow: 0 0 10px 8px rgba(255,0,0,.8); }
    60% { box-shadow: 0 0 10px 8px rgba(255,0,0,.8); }
    100% { box-shadow: 0 0 0px 8px rgba(255,0,0,.8); }
  }

.outerCircle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.mine {
        animation: glowing 1.5s infinite;
    }
}

.outer-container, .opinion-visualization {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.opinion-container {
    flex: 1;
    position: relative;
    vertical-align: middle;
    &.justify-center {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    .no-opinion {
        font-size: 22px;
        // display: inline-block;
        // margin: 50% auto;
        text-align: center;
        margin-top: 16px;
        width: 100%;
        // line-height: 200px;
    }
}

.MuiDialog-paper {
    background-color: #131a2a !important;
    color: #ffffff !important;
}

.wrapper {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.likertscale-wrapper {
    @include mobile {
        max-width: 80%;
    }
    @include desktop {
        max-width: 85%;
    }
    margin: 0 auto;
}

.circular-progress-wrapper {
    width: 100%;
    height: 100%;
    top: 50%;
    position: fixed;
    left: 50%;
}
.answer-issue-loader {
    top: 0%;
    left: 0%;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #7B7B7B;
    opacity: 0.5;
    z-index: 100;
}

.filter-button-wrapper {
    display: flex;
}

.event-banner {
    border-radius: 16px;
    margin: 8px 4px;
    padding: 4px;

    border: rgba(255,255,255,.8) 1px solid;
    width: fit-content;
    text-align: center;
    display: table;
    margin: 8px auto;
    cursor: pointer;
}

.dropdown-button {
    height: 24px;
    border-radius: 8px;
    line-height: 24px;
    margin: 8px 4px;
    padding: 4px;
    width: 100%;
    cursor: pointer;
    border: rgba(255,255,255,.8) 1px solid;

    .MuiFormControl-root {
        width: 100%;
    }

    .dropdown-filter {
        text-overflow: ellipsis;
        .MuiInputBase-root {
            display: grid;
            font-size: 12px;
        }
        svg {
            right: 0;
        }
    }
}

.popup {
    padding: 8px;

    textarea {
        line-height: 18px;
        height: 18px;
        width: 100%;
        font-size: 14px;
        margin: 4px 0;
        color: white;
        background-color: gray;
    }
}
.blurb {
    text-align: center;
}

.creators-text {
    margin-top: 24px;
    // margin-bottom: 24px;
}

.creator {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;

    .person {
        flex: 1 1 75px;
        margin: 24px 16px 16px 0;
        // width: 100%;
        img {
            width: 100%;
            border-radius: 50%;
        }
        .name {
            
        }
    }
}

.MuiDialog-paper {
    border: 1px solid white;

    @include desktop {
        padding: 12px;
    }
    @include mobile {
        padding: 6px;
        .MuiDialogContent-root {
            font-size: 12px !important;
        }
        ol {
            padding-inline-start: 10px !important;
        }
    }
    .MuiButton-root {
        border: 1px solid white;
    }
    a {
        color:rgba(250,250,250,0.8);
        text-decoration: underline;
    }
}